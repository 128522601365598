import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Divider } from "antd";
import { useReactToPrint } from "react-to-print";
import SalaryPercentileChart from "./SalaryPercentileChart";
import AverageSalaryChart from "./AverageSalaryTrend";
import AverageSalaryExperienceChart from "./AverageSalExpChart";
import MostCommonSkillsChart from "./MostCommonSkillChart";
import LogoComponent from "../LogoComponent";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { LoadingOutlined } from "@ant-design/icons";
import { capitalizeWord } from "../../Utils/PaytrendHelperFunctions";
import AverageSalarySkillsChart from "./AverageSalarySkillsChart";
import IndustrySalaryChart from "./AverageSalaryIndustryChart";
import CompanySizeSalaryChart from "./AverageSalaryCompanySizeChart";
import DistributedTreeMapChart from "./LocationDistributionChart";
import JobPostsAcrossLocationsChart from "./jobPostAcrossLocationsChart";
import DistributedBarChart from "./LocationDistributionChart";

const PrintableReport = React.forwardRef((props: any, ref: any) => {
  const { aggregatedData, aggregatedDataLoading } = props;

  const roles = useSelector((state: any) => state.salaryDashboard.role);

  const rolesToInclude = [
    "Data Scientist",
    "UX/UI Designer",
    "Architect",
    "Automation Engineer",
    "Back End Developer",
    "Data Analyst",
    "Data Science & Analytics",
    "Database Administrator",
    "Front End Developer",
    "Full Stack Developer",
    "Quality Assurance",
    "Software Developer",
    "Software Engineer",
    "Technical Architect",
    "Fitness Trainer",
    "Center Head",
    "Slimming Head / Manager",
    "Spa Manager / Supervisor",
  ];

  const title =
    aggregatedData?.[0]?.title?.charAt(0).toUpperCase() +
    aggregatedData?.[0]?.title?.slice(1);

  const description = aggregatedData?.[0]?.description;

  // Group the data by location
  const groupedByLocation = aggregatedData?.reduce((acc: any, item: any) => {
    if (!acc[item.location]) {
      acc[item.location] = [];
    }
    acc[item.location].push(item);
    return acc;
  }, {});

  // const generateTableOfContents = (groupedByLocation: any) => {
  //   let toc = [{ title: "Cover Page", pageNumber: 1 }];

  //   // Add Location Summary page
  //   toc.push({ title: "Location Summary", pageNumber: 2 });

  //   // Add Average Salary by Experience page
  //   toc.push({ title: "Average Salary by Experience", pageNumber: 3 });

  //   // Add Industry Salary page
  //   toc.push({ title: "Industry Salary", pageNumber: 4 });

  //   // Add Experience Data pages
  //   let pageNumber = 5;
  //   Object.keys(groupedByLocation).forEach((location: any) => {
  //     groupedByLocation[location].forEach((item: any, index: any) => {
  //       toc.push({
  //         title: `Experience Data - ${location} (${item.experience} years)`,
  //         pageNumber: pageNumber++,
  //       });
  //     });
  //   });

  //   // Add Most Common Skills page
  //   toc.push({ title: "Most Common Skills", pageNumber: pageNumber });

  //   // Add Average Salary by Skills page
  //   toc.push({ title: "Average Salary by Skills", pageNumber: pageNumber + 1 });

  //   return toc;
  // };

  // const tableOfContents = generateTableOfContents(groupedByLocation);

  const reorderChart5Data = (chart4Data: any, chart5Data: any) => {
    const skillOrder = chart4Data.map((item: any) => item.skill_name); // Get the ordered skills from chart4

    // Reorder chart5Data based on skillOrder
    return skillOrder.map((skill: any) => {
      const skillData = chart5Data.find((data: any) => data.skill === skill);
      return skillData
        ? { skill, salary: skillData.salary }
        : { skill, salary: null };
    });
  };

  if (aggregatedDataLoading)
    return (
      <div className="text-primary d-flex justify-content-center">
        <p>
          Report is getting generated <LoadingOutlined />
        </p>
      </div>
    );

  return (
    <div ref={ref}>
      {groupedByLocation && (
        <div className="report-container">
          <div className="report-page">
            <div
              className="report-header d-flex justify-content-between"
              style={{ marginTop: "-5rem" }}
            >
              <p>Paytrend market report</p>
              <LogoComponent />
            </div>
            <Divider className="my-3" />
            <div className="report-content">
              <h1>{capitalizeWord(title)}</h1>
              <p>
                {description} This report provides detailed insights into salary
                trends, skill distributions, and experience levels for software
                developers across different locations.
              </p>
              <p>We publish salary reports for the following roles.</p>
              <ul></ul>
              {rolesToInclude.sort().map((role: any, index: number) => (
                <li key={index}>{capitalizeWord(role)}</li>
              ))}
              <p>
                This salary survey report contains data up to 31st July 2024. We
                will continue to publish these reports once every quarter,
                providing ongoing insights into salary trends and skill
                distributions across different locations.
              </p>
            </div>
            <div></div>

            <div className="report-footer" style={{ marginTop: "5.5rem" }}>
              <Divider className="my-3" />
              <h4>
                {" "}
                Anyone who downloads the report is personally liable for not
                distributing it to others. By downloading the report, you agree
                not to share any part of it.
              </h4>
            </div>
          </div>
          {/* {aggregatedData[0].data.chart11?.length > 1 &&
            aggregatedData[0].data.chart10?.length > 1 && (
              <div className="report-page">
                <div>
                  <div className="report-header d-flex justify-content-between">
                    <p>Paytrend market report</p>
                    <LogoComponent />
                  </div>
                  <Divider className="my-3" />
                </div>
                <div className="report-content location-summary">
                  <h2 className="">{title}</h2>

                  {aggregatedData[0].data.chart11?.length > 1 && (
                    <div className="d-lg-flex">
                      <Card className="chart-full border-0 custom-card-padding-2">
                        <JobPostsAcrossLocationsChart
                          dataSet={aggregatedData[0].data.chart11}
                          dataSetLoading={aggregatedDataLoading}
                          title={title}
                        />
                      </Card>
                    </div>
                  )}

                  {aggregatedData[0].data.chart10?.length > 1 && (
                    <div className="d-lg-flex">
                      <Card className="chart-full border-0 custom-card-padding-2">
                        <DistributedBarChart
                          dataSet={aggregatedData[0].data.chart10}
                          dataSetLoading={aggregatedDataLoading}
                          title={title}
                        />
                      </Card>
                    </div>
                  )}

                  <div className="report-footer">
                    <Divider className="my-3" />
                    <h4>
                      {" "}
                      Anyone who downloads the report is personally liable for
                      not distributing it to others. By downloading the report,
                      you agree not to share any part of it.
                    </h4>
                  </div>
                </div>
              </div>
            )} */}

          {/* Pages for Each Location */}
          {Object.keys(groupedByLocation).map((location, locIndex) => (
            <div>
              {groupedByLocation[location][0].data.chart6?.length > 1 && (
                <div key={locIndex} className="report-page">
                  <div>
                    <div className="report-header d-flex justify-content-between">
                      <p>Paytrend market report</p>
                      <LogoComponent />
                    </div>
                    <Divider className="my-3" />
                  </div>
                  <div className="report-content location-summary">
                    <h2 className="">
                      {title} |{" "}
                      {location === "all cities in India"
                        ? "All cities in India"
                        : location}
                    </h2>
                    <p>
                      This section provides detailed insights for {location},
                      including average salary trends, skill distributions, and
                      more.
                    </p>
                    <div className="d-lg-flex">
                      <Card className="chart-full border-0">
                        <AverageSalaryExperienceChart
                          dataSet={groupedByLocation[location][0].data.chart6}
                          dataSetLoading={aggregatedDataLoading}
                          title={title}
                          location={location}
                        />
                      </Card>
                    </div>
                    <div
                      className="report-footer"
                      style={{ marginTop: "20 .5%" }}
                    >
                      <Divider className="my-3" />
                      <h4>
                        {" "}
                        Anyone who downloads the report is personally liable for
                        not distributing it to others. By downloading the
                        report, you agree not to share any part of it.
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {groupedByLocation[location][0].data.chart8?.length > 0 && (
                <div className="report-page">
                  <div className="report-header d-flex justify-content-between">
                    <p>Paytrend market report</p>
                    <LogoComponent />
                  </div>
                  <Divider className="my-3" />
                  <h2 className="">
                    {title} |{" "}
                    {location === "all cities in India"
                      ? "All cities in India"
                      : location}
                  </h2>
                  <div className="report-content">
                    <div className="d-lg-flex">
                      <Card className="chart-full border-0">
                        <IndustrySalaryChart
                          dataSet={groupedByLocation[location][0].data.chart8}
                          dataSetLoading={aggregatedDataLoading}
                          title={title}
                          location={location}
                        />
                      </Card>
                    </div>
                  </div>
                  <div className="report-footer" style={{ marginTop: "8.5%" }}>
                    <Divider className="my-3" />
                    <h4>
                      {" "}
                      Anyone who downloads the report is personally liable for
                      not distributing it to others. By downloading the report,
                      you agree not to share any part of it.
                    </h4>
                  </div>
                </div>
              )}

              {groupedByLocation[location][0].data.chart9?.length > 1 && (
                <div className="report-page">
                  <div className="report-header d-flex justify-content-between">
                    <p>Paytrend market report</p>
                    <LogoComponent />
                  </div>
                  <Divider className="my-3" />
                  <h2 className="">
                    {title} |{" "}
                    {location === "all cities in India"
                      ? "All cities in India"
                      : location}
                  </h2>
                  <div className="report-content">
                    <div className="d-lg-flex">
                      <Card className="chart-full border-0">
                        <CompanySizeSalaryChart
                          dataSet={groupedByLocation[location][0].data.chart9}
                          dataSetLoading={aggregatedDataLoading}
                          title={title}
                          location={location}
                        />
                      </Card>
                    </div>
                  </div>
                  <div className="report-footer" style={{ marginTop: "35.5%" }}>
                    <Divider className="my-3" />
                    <h4>
                      {" "}
                      Anyone who downloads the report is personally liable for
                      not distributing it to others. By downloading the report,
                      you agree not to share any part of it.
                    </h4>
                  </div>
                </div>
              )}

              {groupedByLocation[location].map((item: any, index: number) => (
                <div>
                  {item.data.chart2?.length > 1 &&
                    item.data.chart3?.length > 1 && (
                      <div key={index} className="report-page experience-data">
                        <div className="report-header d-flex justify-content-between">
                          <p>Paytrend market report</p>
                          <LogoComponent />
                        </div>
                        <Divider className="my-3" />
                        <div className="report-content">
                          <h3>
                            {title} |{" "}
                            {item.location === "all cities in India"
                              ? "All cities in India"
                              : item.location}{" "}
                            | {item.experience}{" "}
                            {item.experience === 1 ? "year" : "years"} of
                            experience
                          </h3>

                          {item.data.chart2?.length > 3 && (
                            <div className="d-lg-flex">
                              <Card className="chart-full border-0">
                                <SalaryPercentileChart
                                  dataSet={item.data.chart2}
                                  dataSetLoading={aggregatedDataLoading}
                                  title={title}
                                  location={item.location}
                                  experience={item.experience}
                                />
                              </Card>
                            </div>
                          )}
                          {item.data.chart3?.length > 1 && (
                            <div className="d-lg-flex">
                              <Card className="chart-full border-0">
                                <AverageSalaryChart
                                  dataSet={item.data.chart3}
                                  dataSetLoading={aggregatedDataLoading}
                                  title={title}
                                  location={item.location}
                                  experience={item.experience}
                                />
                              </Card>
                            </div>
                          )}

                          <div
                            className="report-footer"
                            style={{ marginTop: "20.5%" }}
                          >
                            <Divider className="my-3" />
                            <h4>
                              {" "}
                              Anyone who downloads the report is personally
                              liable for not distributing it to others. By
                              downloading the report, you agree not to share any
                              part of it.
                            </h4>
                          </div>
                        </div>
                      </div>
                    )}

                  {item.data.chart4?.length > 1 &&
                    item.data.chart5?.length > 1 && (
                      <div className="report-page">
                        <div className="report-header d-flex justify-content-between">
                          <p>Paytrend market report</p>
                          <LogoComponent />
                        </div>
                        <Divider className="my-3" />
                        <div className="report-content">
                          <h3>
                            {title} |{" "}
                            {item.location === "all cities in India"
                              ? "All cities in India"
                              : item.location}{" "}
                            | {item.experience}{" "}
                            {item.experience === 1 ? "year" : "years"} of
                            experience
                          </h3>

                          {item.data.chart4?.length > 1 && (
                            <div className="d-lg-flex">
                              <Card className="chart-full border-0">
                                <MostCommonSkillsChart
                                  dataSet={item.data.chart4}
                                  dataSetLoading={aggregatedDataLoading}
                                  title={title}
                                  location={item.location}
                                  experience={item.experience}
                                />
                              </Card>
                            </div>
                          )}

                          {item.data.chart5?.length > 1 && (
                            <div className="d-lg-flex custom-card-padding">
                              <Card className="chart-full border-0 p-0">
                                <AverageSalarySkillsChart
                                  dataSet={reorderChart5Data(
                                    item.data.chart4,
                                    item.data.chart5
                                  )}
                                  dataSetLoading={aggregatedDataLoading}
                                  title={title}
                                  location={item.location}
                                  experience={item.experience}
                                />
                              </Card>
                            </div>
                          )}
                        </div>
                        <div className="report-footer">
                          <Divider className="my-3" />
                          <h4>
                            {" "}
                            Anyone who downloads the report is personally liable
                            for not distributing it to others. By downloading
                            the report, you agree not to share any part of it.
                          </h4>
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

const StaticReportOutput = () => {
  const printRef = useRef<any>();
  const [isLoaded, setIsLoaded] = useState(false);
  const aggregatedData = useSelector(
    (state: any) => state.salaryDashboard.aggregated
  );
  const aggregatedDataLoading = useSelector(
    (state: any) => state.salaryDashboard.aggregatedloading
  );

  useEffect(() => {
    const images = printRef.current.querySelectorAll("img");
    let loadedImagesCount = 0;

    const onImageLoad = () => {
      loadedImagesCount++;
      if (loadedImagesCount === images.length) {
        setIsLoaded(true);
      }
    };

    images.forEach((image: HTMLImageElement) => {
      if (image.complete) {
        onImageLoad();
      } else {
        image.addEventListener("load", onImageLoad);
        image.addEventListener("error", onImageLoad); // Count errored images as well
      }
    });

    if (images.length === 0) {
      setIsLoaded(true);
    }
  }, []);

  const handleGeneratePdfWithPuppeteer = async () => {
    if (!isLoaded) {
      alert("Please wait until the content is fully loaded");
      return;
    }

    try {
      const htmlContent = printRef.current.innerHTML;
      const response = await fetch("http://localhost:8002/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ htmlContent }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate PDF");
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleDownloadPdf = async () => {
    if (!isLoaded) {
      alert("Please wait until the content is fully loaded");
      return;
    }

    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const canvas = await html2canvas(printRef.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <div>
      {aggregatedData && (
        <div className="mb-3 d-flex justify-content-end">
          <Button onClick={handlePrint} className="btn-primary mr-3">
            Print Report
          </Button>
          <Button onClick={handleDownloadPdf} className="btn-primary mr-3">
            Download as PDF
          </Button>
          <Button
            onClick={handleGeneratePdfWithPuppeteer}
            className="btn-primary"
          >
            Generate PDF with Puppeteer
          </Button>
        </div>
      )}

      <PrintableReport
        ref={printRef}
        aggregatedData={aggregatedData}
        aggregatedDataLoading={aggregatedDataLoading}
      />
    </div>
  );
};

export default StaticReportOutput;
