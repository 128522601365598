import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  blog_path,
  home_path,
  payment_cancel_url,
  payment_success_url,
  paytrend_tool_path,
  static_report_store_path,
  static_report_tool_path,
} from "../Config/config";
import PaytrendTool from "../Components/Paytrend/Tool";
import StaticReportTool from "../Components/StaticReport/Tool";
import StaticReportsStore from "../Components/StaticReportsStore";
import SuccessPage from "../Payment/success";
import CancelPage from "../Payment/cancel";
import BlogPage from "../Components/Blog/blogPost";
import BlogsPage from "../Components/Blog/blogs";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={home_path} element={<StaticReportsStore />} />
        {/* <Route path={login_path} element={<Login />} /> */}
        {/* <Route path={register_path} element={<Register />} /> */}
        <Route path={paytrend_tool_path} element={<PaytrendTool />} />
        <Route path={static_report_tool_path} element={<StaticReportTool />} />
        <Route
          path={static_report_store_path}
          element={<StaticReportsStore />}
        />
        <Route path={blog_path} element={<BlogsPage />} />
        <Route path={`${blog_path}/:slug`} element={<BlogPage />} />

        <Route path={payment_success_url} element={<SuccessPage />} />
        <Route path={payment_cancel_url} element={<CancelPage />} />
        <Route
          path="*"
          element={
            <>
              <h1 className="text-center">404 Page Not Found</h1>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
